
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'UploadedFileModal',
  components: { TmButton, TmModal },
  props: {
    title: {
      type: String,
      default: 'File uploaded',
    },
  },
})
